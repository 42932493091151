<template>
    <div>
        <SingleAuthView
            v-if="isSingleAuthTest"
            :route-back-to-shopping="routeBackToShopping"
            :initial-email="initialEmail"
            :is-page-event-emitted-deferred="isPageEventEmittedDeferred"
            @login-success="onLoginSuccessInSingleAuth($event)"
        />
        <LoginAndRegisterView
            v-else
            :initial-email="initialEmail"
            :current-form-id="currentFormId"
            @navigate-to-form-id="navigateToFormId($event)"
            @login-success="onLoginSuccess($event)"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { AUTHORIZATION_FORMS_IDS } from '@types/CustomerModal';
import { USER_LOGIN_LABEL_EMAIL } from '@analytics-types/Analytics';

import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';
import {
    PATH_TO_REDIRECT_FROM_LOGIN_AND_REGISTER_PAGE,
    STORAGE_TYPE_SESSION,
    REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN,
} from '@configs/storage';
import resetPasswordStates from '@configs/reset-password-states';
import { MODAL_ACCOUNT_VERIFICATION, MODAL_ACCOUNT_VERIFICATION_TYPE } from '@configs/modals';
import { ACCOUNT_VERIFICATION_STATES } from '@configs/account-verification';
import { isSingleInputAuthTestOn } from '@configs/abtest-single-input-auth';
import { DAYS_FOR_RETURNS } from '@localeConfig/keys';

import {
    LOGIN_PAGE_NAME,
    REGISTER_PAGE_NAME,
    FORGOT_PASSWORD_PAGE_NAME,
    CUSTOMER_PAGE_NAME,
    PREFERENCES_FORM_PAGE_NAME,
} from '@router/names';
import {
    FORGOT_PASSWORD_PAGE,
    LOGIN_PAGE,
    REGISTER_PAGE,
    RESET_PASSWORD as RESET_PASSWORD_PAGE,
} from '@router/paths';

import { isModivoClubTestEnabled } from '@modivo-club-assets/modivo-club';

import BasePage from '@pages/mixins/BasePage';

const { mapActions: mapCustomerActions, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);
const { mapState: mapCustomerState, mapGetters: mapConfigGetters } = createNamespacedHelpers(
    'config'
);

export default {
    name: 'LoginAndRegister',

    components: {
        LoginAndRegisterView: () => ({
            /* webpackChunkName: "LoginAndRegister" */
            component: import('@organisms/LoginAndRegisterView/LoginAndRegisterView'),
        }),
        SingleAuthView: () => ({
            /* webpackChunkName: "SingleAuthView" */
            component: import('@organisms/SingleAuthView/SingleAuthView'),
        }),
    },

    mixins: [BasePage()],

    layout: ({ $abTests }) =>
        isSingleInputAuthTestOn($abTests) ? 'authorization' : 'authorizationV2',

    async asyncData({ app, from, route: { name } }) {
        const descriptionArray = [
            app.$t('Top brands and top manufacturers'),
            app.$t('Free delivery and {days} days return', {
                days: app.$configProvider.getConfig(DAYS_FOR_RETURNS),
            }),
            app.$t('Online fashion at MODIVO'),
        ];

        const titleByRouteName = {
            [LOGIN_PAGE_NAME]: app.$t('Login'),
            [REGISTER_PAGE_NAME]: app.$t('Register'),
            [FORGOT_PASSWORD_PAGE_NAME]: app.$t('Reset password'),
        };

        if (process.client) {
            const PAGES_PATHS_NOT_TO_REDIRECT = [
                `/${LOGIN_PAGE}`,
                `/${REGISTER_PAGE}`,
                `/${FORGOT_PASSWORD_PAGE}`,
                ...Object.values(resetPasswordStates).map(
                    state => `/${RESET_PASSWORD_PAGE}/${state}`
                ),
            ];
            const { path = '' } = from || {};

            if (!PAGES_PATHS_NOT_TO_REDIRECT.includes(path)) {
                app.$storage.setItem(
                    PATH_TO_REDIRECT_FROM_LOGIN_AND_REGISTER_PAGE,
                    path,
                    null,
                    STORAGE_TYPE_SESSION
                );
            }
        }

        return {
            meta: {
                title: titleByRouteName[name] || app.$t('Login'),
                description: descriptionArray.join(' | '),
                robots: META_ROBOTS_INDEX_FOLLOW,
            },
            initialEmail: '',
        };
    },

    head() {
        const {
            meta: { title, description, robots },
        } = this;

        return {
            title,
            meta: [
                { hid: 'robots', name: 'robots', content: robots },
                {
                    hid: 'title',
                    name: 'title',
                    content: title,
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: description,
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: title,
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: description,
                },
            ],
        };
    },

    computed: {
        ...mapConfigGetters(['isAccountVerificationEnabled']),
        ...mapCustomerGetters(['isLoggedIn']),
        ...mapCustomerState(['shouldOpenModalNewsletterConfirmation']),

        isSingleAuthTest() {
            return isSingleInputAuthTestOn(this.$abTests);
        },

        currentFormId() {
            return this.formsByPageName[this.$route.name];
        },

        routeBackToShopping() {
            const path =
                this.$storage?.getItem(
                    PATH_TO_REDIRECT_FROM_LOGIN_AND_REGISTER_PAGE,
                    STORAGE_TYPE_SESSION
                ) || '';

            return !path ? this.$navigationContext.getContextPath() : path;
        },

        formsByPageName() {
            const { LOGIN, REGISTER, FORGOT_PASSWORD } = AUTHORIZATION_FORMS_IDS;

            return {
                [LOGIN_PAGE_NAME]: LOGIN,
                [REGISTER_PAGE_NAME]: REGISTER,
                [FORGOT_PASSWORD_PAGE_NAME]: FORGOT_PASSWORD,
            };
        },

        isModivoClubEnabled() {
            return isModivoClubTestEnabled(this.$abTests);
        },
    },

    async mounted() {
        this.initialEmail = await this.getEmailFromMagicLinkQuery();

        await this.isCustomerFetchedDeferred.promise;

        if (this.isLoggedIn) {
            if (this.initialEmail && this.$route.name === REGISTER_PAGE_NAME) {
                await this.logout({ showSuccessMessage: false });

                return;
            }

            this.$router.push({ name: CUSTOMER_PAGE_NAME });
        }
    },

    methods: {
        ...mapCustomerActions(['logout']),

        async getEmailFromMagicLinkQuery() {
            if (!window) {
                return '';
            }

            try {
                const encodedEmail = this.$route.query.magicLinkRegister || '';

                const query = { ...this.$route.query };

                delete query.magicLinkRegister;

                await this.$router.replace({ query });

                return window.atob(encodedEmail);
            } catch {
                return '';
            }
        },

        getPageNameByFormId(formId) {
            const { formsByPageName } = this;

            return (
                Object.keys(formsByPageName).find(
                    pageName => formsByPageName[pageName] === formId
                ) || ''
            );
        },

        navigateToFormId({ formId }) {
            this.$router.push(this.getPageNameByFormId(formId));
        },

        onLoginSuccessInSingleAuth({ type, isRegistration = false }) {
            if (
                this.isAccountVerificationEnabled &&
                isRegistration &&
                type === USER_LOGIN_LABEL_EMAIL &&
                !this.shouldOpenModalNewsletterConfirmation &&
                !this.isModivoClubEnabled
            ) {
                this.$modals.open(MODAL_ACCOUNT_VERIFICATION, {
                    [MODAL_ACCOUNT_VERIFICATION_TYPE]: ACCOUNT_VERIFICATION_STATES.PENDING,
                });
            }

            if (isRegistration) {
                this.$router.push({
                    name: PREFERENCES_FORM_PAGE_NAME,
                });

                return;
            }

            this.$router.push({ path: this.routeBackToShopping });
        },

        onLoginSuccess({ type, isRegistration = false }) {
            if (this.isAccountVerificationEnabled) {
                const shouldRedirectToCustomerPage = this.$storage.getItem(
                    REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN
                );

                if (
                    isRegistration &&
                    type === USER_LOGIN_LABEL_EMAIL &&
                    !this.shouldOpenModalNewsletterConfirmation &&
                    !this.isModivoClubEnabled
                ) {
                    this.$modals.open(MODAL_ACCOUNT_VERIFICATION, {
                        [MODAL_ACCOUNT_VERIFICATION_TYPE]: ACCOUNT_VERIFICATION_STATES.PENDING,
                    });
                }

                if (shouldRedirectToCustomerPage) {
                    this.$storage.removeItem(REDIRECT_TO_CUSTOMER_PAGE_AFTER_LOGIN);

                    this.$router.push({
                        name: CUSTOMER_PAGE_NAME,
                    });

                    return;
                }
            }

            this.$router.push({ path: this.routeBackToShopping });
        },
    },
};
</script>
